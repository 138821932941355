import * as React from 'react';
import faq from './general/faq';
import { Props } from '../src/components/sections/sluzby/order/order.props';
export default {
    seo: {
        title: 'Internet',
        description: 'Rozsáhlá internetová vysokorychlostní síť na míru s rychlostí až 1000mbps se skvělou podporou, neustálým servisem, chytrou televizí s možností záznamu až dvou týdnů.'
    },
    sections: {
        hero: {
            heading: 'Naše tarify a možnosti připojení',
        },
        order: [
            {
                id: 'domacnost',
                heading: 'Internet pro domácnost',
                link: 'domacnost',
                specification: {
                    name: 'základní konfigurace',
                    cost: 249,
                    speed: '100/20',
                    connection: '5G',
                    tv: true,
                },
                content: (
                    <>
                        <p>Pokud hledáte internetové připojení pro váš dům, byt či chatu, jste tu správně.</p>
                        <p>Nechte si náš internet zapojit kde jen potřebujete, stačí se s námi spojit a my už vše zařídíme.</p>
                    </>
                ),
            },
            {
                id: 'firma',
                heading: 'Internet pro firmy',
                link: 'firma',
                specification: {
                    name: 'základní konfigurace',
                    cost: 399,
                    speed: '2000',
                    connection: '5G, 60G',
                    tv: false,
                },
                content: (
                    <>
                        <p>Vyhrazená kapacita přímo pro Vás s garantovanými parametry a dostupností.</p>
                        <p>To je náš internet pro Firmy.</p>
                        <ul>
                            <li>cena při pořízení teď platí už napořád</li>
                            <li>až 2Gbit/s bez omezení přenesených dat</li>
                            <li>bez skrytých poplatků za pevnou linku</li>
                            <li>non-stop technická podpora zdarma</li>
                        </ul>
                    </>
                ),
            },
            {
                id: 'bytovy-dum',
                heading: 'Internet pro bytové domy',
                link: 'bytovy-dum',
                specification: {
                    name: 'základní konfigurace',
                    cost: 249,
                    speed: '1000',
                    connection: '60G',
                    tv: false,
                },
                content: (
                    <>
                        <p>
                            Prémiové připojení pro více účastníků v domě. S našimi nejnovějšími technologiemi pro Vás v domě
                            zařídíme to nejrychlejší připojení, které v dané v dané lokalitě v naší síti dostupné. Připojení
                            srovnatelné s rychlostmi na optickém internetu, rychlosti až 1Gbit/s.
                        </p>
                    </>
                ),
            },
        ] as Props[],
        faq: faq
    },
};
