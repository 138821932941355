import * as React from 'react';
import { SectionFaq, SectionHero } from '../../components/sections/general';
import Seo from '../../components/sections/general/seo';
import Order from '../../components/sections/sluzby/order';
import Layout from '../../layouts';
import Data from '../../../content/internet';

const { seo, sections } = Data;
export default () => {
    return (
        <Layout>
            <Seo title={seo.title} />
            <SectionHero heading={sections.hero.heading} />
            {sections.order.map((data, key) => (<Order {...data} key={key+data.link} />))}
            <SectionFaq questions={sections.faq} />
        </Layout>
    );
};
