// @ts-ignore
import React, {useLayoutEffect, useRef} from 'react'
import Button from '../../../button'
import {Container, Specification, Text, Wrapper} from './order.style'
import {Props} from './order.props'
import {gsap} from 'gsap'
import { HeadingAnimation } from '../../../../animation/heading'
import {ScrollParams} from '../../../../animation/scroll'
// @ts-ignore
import Routes from '../../../../../content/routes.json'


export default ({link,specification, heading, content, id}: Props) => {
    const orderRef = useRef()
    const order = gsap.utils.selector(orderRef)

    useLayoutEffect(() => {
        gsap.timeline({scrollTrigger: {trigger: orderRef.current, ...ScrollParams}})
            .from(order('.order_heading'), HeadingAnimation.fromVars)
            .from(order(".order_text"), {
                x: -50,
                opacity: 0
            }, "<")
            .from(order(".order_specification"), {
                opacity: 0
            }, "<")
            .from(order(".order_button"), {
                y: 50,
                opacity: 0
            }, "<")
    })
    return (
        <Container id={id} ref={orderRef}>
            <h2 className="order_heading">{heading}</h2>
            <Wrapper>
                <Text className={`order_text`}>
                    <div>
                        {content}
                    </div>
                </Text>
                <Specification className="order_specification">
                    <li>cena od:<span className='green'>{specification.cost}kč/m</span></li>
                    <li>rychlost
                        až:<span>{specification.speed}</span>
                    </li>
                    <li>technologie:<span>{specification.connection}</span></li>
                    <li>možnost TV:<span>{specification.tv? 'ANO' : 'NE'}</span></li>
                </Specification>
                <Button className="order_button" href={Routes.objednat+'/'+link} color='blue' size='md' animation='translateXY'>
                    Objednat
                </Button>
            </Wrapper>
        </Container>
    )
}
