import styled from "styled-components"
import Section from "../../"
import { fonts, colors } from "../../../../styles/constants"
import { Card } from "../../../card"

const Container = styled(Section)`
    @media (min-width: 700px) {
        width: 65vw;
        max-width: 1200px;
    }
`
const Wrapper = styled.article`
    display: grid;
    grid-template-rows: 5fr 1fr;
    grid-template-columns: 4fr 2fr;
    gap: 1em;
    @media (max-width: 1300px) {
        font-size: ${fonts.fs__default_mobile};
    }
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
    }
`
const Text = styled.div`
    ${Card};
    grid-row: span 2;
    padding: 2rem;
    div {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    ul {
        padding-left: 15px;
        list-style-type: circle;
    }
`
const Specification = styled.ul`
    ${Card};
    padding: 2rem;
    white-space: nowrap;

    li {
        display: flex;

        span {
            font-weight: bold;
            margin-left: auto;

            &.green {
                color: ${colors.clr_g};
            }
        }
    }
`

export {Specification, Text, Wrapper, Container}